import "./App.css";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import OsloMap from "./components/sections/maps/osloMap";
import TelcofyPin from "./components/ui/TelcofyPin";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/oslo" element={<OsloMap />} />
        <Route path="/telcofy-pin" element={<TelcofyPin />} />
      </Routes>
    </div>
  );
}

export default App;
