import React from "react";

const SEAFOAM = "#71EEB8";

const Navbar = () => {
  return (
    <nav className="fixed w-full z-50 bg-white/80 backdrop-blur-lg border-b border-gray-100">
      <div className="max-w-7xl mx-auto px-6 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <img
              src="/telcofyPin.png"
              alt="Telcofy Logo"
              className="h-8 w-auto"
            />
            <div className="text-3xl font-bold text-gray-900">
              Telco<span style={{ color: SEAFOAM }}>fy</span>
            </div>
          </div>
          <div className="hidden md:flex items-center gap-8">
            <a href="#solutions" className="text-gray-600 hover:text-gray-900">
              Solutions
            </a>
            <a href="#features" className="text-gray-600 hover:text-gray-900">
              Features
            </a>
            <a href="#about" className="text-gray-600 hover:text-gray-900">
              About
            </a>
            <button
              className="px-6 py-3 rounded-full text-white font-semibold transition-all hover:scale-105"
              style={{ backgroundColor: SEAFOAM }}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;