import React from "react";
import AnimatedTelcofyPin from "../../ui/TelcofyPin";

const SEAFOAM = "#71EEB8";
const EU_BLUE = "#002D63"; // Darker blue to match mockup

const EurostatSection = () => {
  return (
    <section className="relative py-16 md:py-24 bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Logos Centered Above Box - Optimized for different screen sizes */}
        <div className="flex justify-center mb-16 md:mb-20">
          <div className="flex items-center justify-center gap-6 md:gap-10 lg:gap-12">
            {/* Eurostat Logo - Larger, fixed sizes for better visibility */}
            <div className="flex-shrink-0">
              <img
                src="/logos/eurostat-logo.png"
                alt="Eurostat Logo"
                className="h-16 sm:h-20 md:h-24 lg:h-28 w-auto object-contain"
              />
            </div>

            {/* Cross symbol with lines - More prominent */}
            <div className="flex items-center gap-3 md:gap-4">
              <div className="w-12 md:w-20 lg:w-24 h-px bg-gray-300" />
              <div className="text-3xl md:text-4xl font-light text-gray-400">
                ×
              </div>
              <div className="w-12 md:w-20 lg:w-24 h-px bg-gray-300" />
            </div>

            {/* Telcofy Pin - Larger, fixed sizes */}
            <div className="flex-shrink-0 mt-2">
              <AnimatedTelcofyPin
                width={100}
                height={100}
                className="sm:scale-125 md:scale-150 lg:scale-175 transform-gpu"
              />
            </div>
          </div>
        </div>

        {/* Split-color box - Full width on mobile, constrained on larger screens */}
        <div className="relative rounded-xl md:rounded-2xl overflow-hidden mb-16 md:mb-24 shadow-xl">
          {/* Background split */}
          <div className="absolute inset-0 flex">
            <div
              className="w-1/2 h-full"
              style={{ backgroundColor: EU_BLUE }}
            />
            <div
              className="w-1/2 h-full"
              style={{ backgroundColor: SEAFOAM }}
            />
          </div>

          {/* Content container with responsive padding */}
          <div className="relative py-12 md:py-20 px-4 md:px-8">
            {/* Title and description with responsive text sizes */}
            <div className="flex flex-col items-center text-center">
              <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 text-shadow">
                Turning Regulation Into Opportunity
              </h2>

              <div className="w-16 md:w-24 h-1 mx-auto rounded-full mb-6 md:mb-8 bg-white" />

              <p className="text-base sm:text-lg md:text-xl text-white max-w-3xl mx-auto text-shadow leading-relaxed">
                Our EU-compliant data pipeline transforms telco regulatory
                requirements into valuable insights that power smarter cities,
                better business decisions, and official statistics.
              </p>
            </div>
          </div>
        </div>

        {/* Pipeline Visualization - Stack on mobile, grid on larger screens */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-10 mb-16">
          {/* Input: Telco Data */}
          <div className="relative group">
            <div
              className="absolute top-0 left-0 right-0 h-1 rounded-t-xl"
              style={{ backgroundColor: EU_BLUE }}
            />
            <div className="bg-white rounded-xl p-6 md:p-8 h-full border border-gray-100 pt-8 md:pt-10 hover:border-[#003399]/20 transition-colors shadow-sm hover:shadow-md">
              <div
                className="w-14 h-14 md:w-16 md:h-16 rounded-xl flex items-center justify-center mb-4 md:mb-6 transition-all duration-300 group-hover:scale-105"
                style={{ backgroundColor: `${EU_BLUE}15` }}
              >
                <svg
                  viewBox="0 0 24 24"
                  width="32"
                  height="32"
                  stroke={EU_BLUE}
                  fill="none"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                  <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                  <line x1="12" y1="22.08" x2="12" y2="12"></line>
                </svg>
              </div>

              <h3 className="text-xl md:text-2xl font-semibold text-gray-900 mb-3 md:mb-4">
                Telco Network Data
              </h3>

              <p className="text-sm md:text-base text-gray-600 leading-relaxed">
                Transform your network data into standardized,
                revenue-generating insights while maintaining complete data
                sovereignty
              </p>
            </div>
          </div>

          {/* Processing: Pipeline */}
          <div className="relative group">
            <div
              className="absolute top-0 left-0 right-0 h-1 rounded-t-xl"
              style={{ backgroundColor: SEAFOAM }}
            />
            <div className="bg-white rounded-xl p-6 md:p-8 h-full border border-gray-100 pt-8 md:pt-10 hover:border-[#71EEB8]/20 transition-colors shadow-sm hover:shadow-md">
              <div
                className="w-14 h-14 md:w-16 md:h-16 rounded-xl flex items-center justify-center mb-4 md:mb-6 transition-all duration-300 group-hover:scale-105"
                style={{ backgroundColor: `${SEAFOAM}15` }}
              >
                <svg
                  viewBox="0 0 24 24"
                  width="32"
                  height="32"
                  stroke={SEAFOAM}
                  fill="none"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="22" y1="12" x2="2" y2="12"></line>
                  <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path>
                  <line x1="6" y1="16" x2="6.01" y2="16"></line>
                  <line x1="10" y1="16" x2="10.01" y2="16"></line>
                </svg>
              </div>

              <h3 className="text-xl md:text-2xl font-semibold text-gray-900 mb-3 md:mb-4">
                Eurostat Pipeline
              </h3>

              <p className="text-sm md:text-base text-gray-600 leading-relaxed">
                Our EU-compliant processing pipeline applies Eurostat
                methodology with enhanced privacy protections and quality
                measures.
              </p>
            </div>
          </div>

          {/* Output: Insights */}
          <div className="relative group">
            <div
              className="absolute top-0 left-0 right-0 h-1 rounded-t-xl bg-gradient-to-r"
              style={{
                backgroundImage: `linear-gradient(to right, ${EU_BLUE}, ${SEAFOAM})`,
              }}
            />
            <div className="bg-white rounded-xl p-6 md:p-8 h-full border border-gray-100 pt-8 md:pt-10 hover:border-[#71EEB8]/20 transition-colors shadow-sm hover:shadow-md">
              <div
                className="w-14 h-14 md:w-16 md:h-16 rounded-xl flex items-center justify-center mb-4 md:mb-6 transition-all duration-300 group-hover:scale-105"
                style={{
                  backgroundImage: `linear-gradient(135deg, ${EU_BLUE}15, ${SEAFOAM}15)`,
                }}
              >
                <svg
                  viewBox="0 0 24 24"
                  width="32"
                  height="32"
                  stroke={SEAFOAM}
                  fill="none"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                </svg>
              </div>

              <h3 className="text-xl md:text-2xl font-semibold text-gray-900 mb-3 md:mb-4">
                Validated Insights
              </h3>

              <p className="text-sm md:text-base text-gray-600 leading-relaxed">
                Scientifically validated mobility insights that meet statistical
                quality standards while preserving signal integrity.
              </p>
            </div>
          </div>
        </div>

        {/* Benefits/Features - Wrap better on mobile */}
        <div className="flex flex-wrap justify-center gap-3 md:gap-6 max-w-4xl mx-auto">
          {[
            { text: "GDPR Compliant", color: EU_BLUE },
            { text: "Open Methodology", color: SEAFOAM },
            { text: "Fully Transparent", color: EU_BLUE },
            { text: "Scientifically Validated", color: SEAFOAM },
          ].map((feature, index) => (
            <div
              key={index}
              className="px-4 md:px-6 py-2 md:py-3 rounded-full bg-white text-sm md:text-base text-gray-700 border-2 flex items-center gap-2 shadow-sm"
              style={{ borderColor: `${feature.color}30` }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 6L9 17L4 12"
                  stroke={feature.color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>{feature.text}</span>
            </div>
          ))}
        </div>
      </div>

      <style jsx>{`
        .text-shadow {
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        }
      `}</style>
    </section>
  );
};

export default EurostatSection;
