import React from "react";
import { AlertTriangle, Scale, Building2, FlaskConical } from "lucide-react";

const SEAFOAM = "#71EEB8";
const OCEAN_BLUE = "#4A90E2";
const SLATE_BLUE = "#505F7A";

const problems = [
  {
    icon: <AlertTriangle size={32} />,
    title: "Market Disconnect",
    points: [
      "Quick profits prioritized over scientific validity",
      "Black-box approaches that can't be validated",
      "Lack of transparency about accuracy",
      '"Move fast" mindset endangering decisions that impact millions',
    ],
    accentColor: SLATE_BLUE,
  },
  {
    icon: <Scale size={32} />,
    title: "Regulatory Pressure",
    points: [
      "New EU regulations requiring standardized sharing",
      "MNOs lack expertise for compliance",
      "Need for transparent, validated methodologies",
    ],
    accentColor: OCEAN_BLUE,
  },
  {
    icon: <Building2 size={32} />,
    title: "Missed Opportunity",
    points: [
      "Telcos sitting on valuable data assets",
      "Solutions either too commercial or too academic",
      "Need for scientific rigor with commercial viability",
    ],
    accentColor: SEAFOAM,
  },
];

const ProblemStatement = () => {
  return (
    <section className="relative py-24 bg-white overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0" aria-hidden="true">
        {/* Subtle geometric pattern */}
        <div
          className="absolute inset-0 opacity-[0.02]"
          style={{
            backgroundImage: `radial-gradient(${SEAFOAM} 1px, transparent 1px)`,
            backgroundSize: "48px 48px",
          }}
        />

        {/* Soft shapes */}
        <div
          className="absolute top-20 -right-40 w-[600px] h-[600px] rounded-full opacity-[0.05]"
          style={{
            background: SEAFOAM,
            filter: "blur(80px)",
          }}
        />
        <div
          className="absolute -bottom-40 -left-40 w-[600px] h-[600px] rounded-full opacity-[0.05]"
          style={{
            background: SEAFOAM,
            filter: "blur(80px)",
          }}
        />
      </div>

      <div className="relative max-w-7xl mx-auto px-6">
        {/* Header with scientific theme */}
        <div className="max-w-3xl mx-auto text-center mb-16">
          <div
            className="inline-flex items-center justify-center w-16 h-16 rounded-2xl mb-6"
            style={{ backgroundColor: `${SEAFOAM}15` }}
          >
            <FlaskConical size={32} style={{ color: SEAFOAM }} />
          </div>

          <h2 className="text-4xl font-bold text-gray-900 mb-6">
            The Telco Data Dilemma:
            <span className="block mt-2 text-3xl font-medium">
              Beyond Quick Fixes
            </span>
          </h2>

          <p className="text-xl text-gray-600">
            The industry needs a solution that combines scientific validity with
            commercial viability - not just another black-box analytics
            platform.
          </p>
        </div>

        {/* Problems Grid */}
        <div className="grid lg:grid-cols-3 gap-8">
          {problems.map((problem, index) => (
            <div key={index} className="relative group">
              {/* Top Accent Line */}
              <div
                className="absolute top-0 left-0 right-0 h-1 rounded-t-xl transition-all duration-300"
                style={{
                  backgroundColor: problem.accentColor,
                  opacity: 0.8,
                }}
              />

              {/* Card Content */}
              <div className="bg-white rounded-xl p-8 h-full border border-gray-100 pt-10 hover:border-[#71EEB8]/20 transition-colors">
                {/* Icon */}
                <div
                  className="w-16 h-16 rounded-xl flex items-center justify-center mb-6 transition-all duration-300 group-hover:scale-105"
                  style={{
                    backgroundColor: `${problem.accentColor}10`,
                    color: problem.accentColor,
                  }}
                >
                  {problem.icon}
                </div>

                {/* Title */}
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                  {problem.title}
                </h3>

                {/* Points */}
                <ul className="space-y-4">
                  {problem.points.map((point, i) => (
                    <li
                      key={i}
                      className="flex items-start gap-3 text-gray-600"
                    >
                      <div
                        className="w-1.5 h-1.5 rounded-full mt-2.5"
                        style={{ backgroundColor: problem.accentColor }}
                      />
                      <span className="text-lg">{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        {/* Bottom Message */}
        <div className="mt-16 text-center bg-white rounded-2xl p-8 border border-gray-100">
          <div className="max-w-3xl mx-auto">
            <p className="text-xl text-gray-800">
              We're building a bridge between
              <span
                className="ml-2 px-4 py-1 rounded-full text-white inline-flex"
                style={{ backgroundColor: OCEAN_BLUE }}
              >
                academic rigor
              </span>
              <span className="block mt-2">
                and
                <span
                  className="ml-2 px-4 py-1 rounded-full text-white inline-flex"
                  style={{ backgroundColor: SEAFOAM }}
                >
                  commercial value
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProblemStatement;
