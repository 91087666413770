import React, { useState, useEffect } from "react";

const SEAFOAM = "#71EEB8";

const ImpactSection = () => {
  const [activeTab, setActiveTab] = useState("resilience");
  const [isAutoScrollPaused, setIsAutoScrollPaused] = useState(false);

  // Auto-scroll through tabs
  useEffect(() => {
    if (isAutoScrollPaused) return;

    const tabOrder = [
      "resilience",
      "sustainability",
      "events",
      "carbon",
      "more",
    ];
    const interval = setInterval(() => {
      setActiveTab((current) => {
        const currentIndex = tabOrder.indexOf(current);
        return tabOrder[(currentIndex + 1) % tabOrder.length];
      });
    }, 6000);

    return () => clearInterval(interval);
  }, [isAutoScrollPaused]);

  const impacts = {
    resilience: {
      title: "Urban Resilience",
      description:
        "Creating adaptive cities that can respond to climate events and rapid urban changes. Our mobility insights help cities prepare for and recover from disruptions while maintaining essential services.",
      image: "/pictures/police.jpg",
      imageAlt: "Urban resilience visualization",
    },
    sustainability: {
      title: "Sustainability",
      description:
        "Transforming cities into sustainable environments where daily needs are just a short walk away. We're helping create neighborhoods that promote walking and reduce unnecessary travel.",
      image: "/time-dependent-reachable-area-optimized.gif",
      imageAlt: "Sustainability visualization",
    },
    events: {
      title: "Event Impact",
      description:
        "Revolutionizing how cities manage large gatherings and cultural events. Our insights help ensure events run smoothly while maintaining community comfort and safety.",
      image: "/pictures/festival.jpg",
      imageAlt: "Event impact visualization",
    },
    carbon: {
      title: "Carbon Reduction",
      description:
        "Leading the charge in reducing urban carbon emissions through smarter mobility planning. We help cities identify and implement high-impact carbon reduction strategies.",
      image: "/pictures/oil_rig.jpg",
      imageAlt: "Carbon reduction visualization",
    },
    more: {
      title: "And Much More",
      description:
        "From retail optimization to healthcare accessibility, our mobility science powers insights across numerous sectors. Discover how mobility data can transform your domain.",
      image: "/pictures/city_scenes.webp",
    },
  };

  return (
    <section className="relative py-24 bg-white overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0" aria-hidden="true">
        {/* Subtle grid */}
        <div
          className="absolute inset-0 opacity-[0.02]"
          style={{
            backgroundImage: `radial-gradient(${SEAFOAM} 1px, transparent 1px)`,
            backgroundSize: "40px 40px",
          }}
        />

        {/* Accent Areas */}
        <div
          className="absolute right-1/4 top-24 w-96 h-96 opacity-10 rounded-full"
          style={{ backgroundColor: SEAFOAM }}
        />
        <div
          className="absolute -left-20 top-1/2 w-80 h-80 opacity-10 rounded-full"
          style={{ backgroundColor: SEAFOAM }}
        />
      </div>

      <div className="relative max-w-7xl mx-auto px-6">
        {/* Section Header */}
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            The Science of Human Mobility
          </h2>
          <div
            className="w-20 h-1 mx-auto rounded-full mb-6"
            style={{ backgroundColor: SEAFOAM }}
          />
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Beyond data pipelines, we're uncovering the hidden patterns that
            shape our cities and communities. Every movement tells a story.
          </p>
        </div>

        {/* Interactive tabs */}
        <div
          className="flex flex-wrap gap-4 mb-8 justify-center"
          onMouseEnter={() => setIsAutoScrollPaused(true)}
          onMouseLeave={() => setIsAutoScrollPaused(false)}
        >
          {Object.entries(impacts).map(([key, impact]) => (
            <button
              key={key}
              onClick={() => setActiveTab(key)}
              className={`
                btn btn-lg rounded-full transition-all duration-300 transform
                ${
                  activeTab === key
                    ? "text-white font-semibold scale-105 shadow-lg"
                    : "btn-ghost hover:scale-105 hover:shadow-md text-gray-800 border-2 border-gray-100 hover:border-gray-200"
                }
              `}
              style={{
                backgroundColor: activeTab === key ? SEAFOAM : "white",
              }}
            >
              {impact.title}
            </button>
          ))}
        </div>

        {/* Content area */}
        <div className="card p-8 transition-all duration-500 bg-white/80 backdrop-blur-sm border border-gray-100">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="space-y-6">
              <h3 className="text-2xl font-bold text-gray-900">
                {impacts[activeTab].title}
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                {impacts[activeTab].description}
              </p>
            </div>

            <div className="relative">
              <img
                src={impacts[activeTab].image}
                alt={impacts[activeTab].imageAlt}
                className="rounded-xl shadow-lg"
              />
              <div
                className="absolute inset-0 rounded-xl"
                style={{
                  background: `linear-gradient(45deg, ${SEAFOAM}15, ${SEAFOAM}05)`,
                }}
              />
            </div>
          </div>
        </div>

        {/* Progress indicator */}
        <div className="mt-6 flex justify-center gap-2">
          {Object.keys(impacts).map((key) => (
            <div
              key={key}
              className={`
                w-2 h-2 rounded-full transition-all duration-300
                ${activeTab === key ? "w-8" : ""}
              `}
              style={{
                backgroundColor: activeTab === key ? SEAFOAM : "#E5E7EB",
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ImpactSection;
