import React from "react";

const AnimatedTelcofyPin = ({ width = 100, height = 100, className = "" }) => {
  return (
    <div className={`relative ${className}`} style={{ width, height }}>
      {/* Base pin image */}
      <img
        src="/telcofyPin.svg"
        alt="Telcofy Pin"
        className="w-full h-full relative z-10"
      />

      {/* Container for circles with perspective */}
      <div
        className="absolute top-[85%] left-0 right-0 flex items-center justify-center"
        style={{ perspective: "1000px" }}
      >
        <div
          className="relative w-full h-8 transform-gpu"
          style={{ transformStyle: "preserve-3d", transform: "rotateX(60deg)" }}
        >
          {/* Animated circles */}
          <div
            className="absolute left-1/2 -translate-x-1/2 w-4 h-4 rounded-full border border-emerald-400 opacity-75"
            style={{
              animation: "ping-slow 2s cubic-bezier(0, 0, 0.2, 1) infinite",
            }}
          />
          <div
            className="absolute left-1/2 -translate-x-1/2 w-4 h-4 rounded-full border border-emerald-400 opacity-75"
            style={{
              animation:
                "ping-medium 2s cubic-bezier(0, 0, 0.2, 1) infinite 0.7s",
            }}
          />
          <div
            className="absolute left-1/2 -translate-x-1/2 w-4 h-4 rounded-full border border-emerald-400 opacity-75"
            style={{
              animation:
                "ping-fast 2s cubic-bezier(0, 0, 0.2, 1) infinite 1.4s",
            }}
          />
        </div>
      </div>

      <style>
        {`
          @keyframes ping-slow {
            0% {
              transform: translateX(-50%) scale(1);
              opacity: 0.75;
            }
            100% {
              transform: translateX(-50%) scale(6);
              opacity: 0;
            }
          }

          @keyframes ping-medium {
            0% {
              transform: translateX(-50%) scale(1);
              opacity: 0.75;
            }
            100% {
              transform: translateX(-50%) scale(6);
              opacity: 0;
            }
          }

          @keyframes ping-fast {
            0% {
              transform: translateX(-50%) scale(1);
              opacity: 0.75;
            }
            100% {
              transform: translateX(-50%) scale(6);
              opacity: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default AnimatedTelcofyPin;
