import React from "react";
import { Code2, Target, Waves, Users, ArrowUpRight } from "lucide-react";

const SEAFOAM = "#71EEB8";

const CorePrinciples = () => {
  const principles = [
    {
      icon: <Code2 size={36} />,
      title: "Code Over Storage",
      description: "Our pipelines are pure code, not stored data",
      impact:
        "This means reduced costs, less complexity, and continuous innovation",
      highlight: "innovation",
    },
    {
      icon: <Target size={36} />,
      title: "Accuracy First",
      description:
        "We prioritize reliable insights over deceptively precise measurements",
      impact: "Leading to trustworthy, actionable data you can rely on",
      highlight: "trustworthy",
    },
    {
      icon: <Waves size={36} />,
      title: "Signal Integrity",
      description:
        "Transparent assumptions that preserve the true signal in your data",
      impact: "Get real insights, not just reflections of preconceptions",
      highlight: "real insights",
    },
    {
      icon: <Users size={36} />,
      title: "Community Powered",
      description: "Built on proven research and open-source foundations",
      impact:
        "Leverage battle-tested solutions rather than reinventing the wheel",
      highlight: "battle-tested",
    },
  ];

  return (
    <section className="py-24">
      <div className="max-w-7xl mx-auto px-6">
        {/* Section Header */}
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Our Core Principles
          </h2>
          <div
            className="w-20 h-1 mx-auto rounded-full"
            style={{ backgroundColor: SEAFOAM }}
          ></div>
        </div>

        {/* Principles Grid */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {principles.map((principle, index) => (
            <div
              key={index}
              className="group bg-white rounded-xl p-8 border border-gray-100 hover:border-[#71EEB8]/30 transition-all duration-300"
            >
              {/* Icon */}
              <div
                className="w-16 h-16 rounded-xl flex items-center justify-center mb-6 transition-all duration-300 group-hover:scale-105"
                style={{ backgroundColor: `${SEAFOAM}15` }}
              >
                {React.cloneElement(principle.icon, {
                  style: { color: SEAFOAM },
                })}
              </div>

              {/* Content */}
              <div className="space-y-4">
                <h3 className="text-2xl font-semibold text-gray-900">
                  {principle.title}
                </h3>

                <p className="text-gray-600 text-lg leading-relaxed">
                  {principle.description}
                </p>

                <p className="text-gray-800 text-lg">
                  {principle.impact
                    .split(principle.highlight)
                    .map((part, i, arr) => (
                      <React.Fragment key={i}>
                        {part}
                        {i < arr.length - 1 && (
                          <span
                            className="font-semibold"
                            style={{ color: SEAFOAM }}
                          >
                            {principle.highlight}
                          </span>
                        )}
                      </React.Fragment>
                    ))}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Section CTA */}
        <div className="text-center">
          <button
            className="inline-flex items-center gap-2 px-8 py-4 rounded-full text-white font-semibold transition-all hover:scale-105"
            style={{ backgroundColor: SEAFOAM }}
          >
            View Our Methodology
            <ArrowUpRight size={20} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default CorePrinciples;
