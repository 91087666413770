import React from "react";
import { ArrowUpRight } from "lucide-react";

const SEAFOAM = "#71EEB8";

const HeroSection = () => {
  return (
    <section className="relative pt-32 pb-20 overflow-hidden">
      {/* Background Pattern - More reliable than gradient blurs */}
      <div className="absolute inset-0" aria-hidden="true">
        {/* Subtle Grid */}
        <div
          className="absolute inset-0 opacity-[0.02]"
          style={{
            backgroundImage:
              "radial-gradient(circle at 1px 1px, #71EEB8 1px, transparent 0)",
            backgroundSize: "40px 40px",
          }}
        />

        {/* Accent Areas - Using solid colors with opacity instead of blurs */}
        <div
          className="absolute right-1/4 top-24 w-96 h-96 opacity-10 rounded-full"
          style={{ backgroundColor: SEAFOAM }}
        />
        <div
          className="absolute -left-20 top-1/2 w-80 h-80 opacity-10 rounded-full"
          style={{ backgroundColor: SEAFOAM }}
        />

        {/* Optional: SVG patterns for texture */}
        <svg
          className="absolute inset-0 w-full h-full opacity-[0.02]"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <pattern
              id="grid"
              width="40"
              height="40"
              patternUnits="userSpaceOnUse"
            >
              <path
                d="M 40 0 L 0 0 0 40"
                fill="none"
                stroke={SEAFOAM}
                strokeWidth="0.5"
              />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#grid)" />
        </svg>
      </div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-6 text-center">
        {/* Feature Banner */}
        <div className="inline-block mb-8 px-5 py-2 rounded-full bg-white/80 backdrop-blur-sm border border-gray-100">
          <span style={{ color: SEAFOAM }} className="font-semibold mr-2">
            New
          </span>
          <span className="text-gray-600">EU Compliance Pipeline v2.0</span>
        </div>

        {/* Tagline */}
        <div className="mb-12">
          <h1 className="text-6xl md:text-7xl font-bold tracking-tight text-gray-900">
            Human Mobility
            <span className="block mt-2">Science</span>
          </h1>
          <div
            className="mt-4 text-3xl md:text-4xl font-medium tracking-normal"
            style={{ color: SEAFOAM }}
          >
            Powered by Telco Data
          </div>
        </div>

        <p className="text-xl md:text-2xl text-gray-600 mb-12 max-w-3xl mx-auto">
          Transform network data into scientific insights with transparent,
          proven methodologies
        </p>

        <div className="flex flex-wrap justify-center gap-6">
          <button
            className="group px-8 py-4 rounded-full text-white font-semibold flex items-center gap-2 transition-all hover:scale-105"
            style={{ backgroundColor: SEAFOAM }}
          >
            Start Exploring
            <ArrowUpRight className="group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
          </button>

          <button className="px-8 py-4 rounded-full text-gray-600 border-2 border-gray-100 hover:border-gray-200 transition-colors">
            View Methodology
          </button>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
