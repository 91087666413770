import React, { useEffect, useRef } from "react";
import DeckGL from "@deck.gl/react";
import { TileLayer } from "@deck.gl/geo-layers";
import { BitmapLayer } from "@deck.gl/layers";

const INITIAL_VIEW_STATE = {
  longitude: 10.7522,
  latitude: 59.9139,
  zoom: 11,
  pitch: 0,
  bearing: 0,
};

function OsloMap() {
  const deckRef = useRef(null);

  useEffect(() => {
    const currentDeck = deckRef.current;
    return () => {
      if (currentDeck?.deck) {
        currentDeck.deck.finalize();
      }
    };
  }, []);

  const layer = new TileLayer({
    data: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
    minZoom: 0,
    maxZoom: 19,
    tileSize: 256,
    renderSubLayers: (props) => {
      const {
        bbox: { west, south, east, north },
      } = props.tile;

      return new BitmapLayer({
        id: props.id,
        image: props.data,
        bounds: [west, south, east, north],
      });
    },
  });

  return (
    <div className="relative w-full h-96">
      <DeckGL
        ref={deckRef}
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        layers={[layer]}
      />
    </div>
  );
}

export default OsloMap;
