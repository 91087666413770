import React from "react";
import Navbar from "../components/layout/Navbar";
import HeroSection from "../components/sections/home/HeroSection";
import CorePrinciples from "../components/sections/home/CorePrinciplesSection";
import ProblemStatement from "../components/sections/home/ProblemStatementSection";
import ImpactSection from "../components/sections/home/ImpactSection";
import EurostatSection from "../components/sections/home/EurostatSection";
const LandingPage = () => {
  return (
    <div className="min-h-screen bg-white">
      <Navbar />

      {/* 1. Vision & Hook: Introducing Human Mobility Science */}
      <HeroSection />
      <div className="relative py-10">
        <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center gap-2">
          <div
            className="w-px h-10 opacity-20"
            style={{ backgroundColor: "#71EEB8" }}
          />
          <div
            className="w-2 h-2 rounded-full"
            style={{ backgroundColor: "#71EEB8" }}
          />
        </div>
      </div>

      {/* 2. Why: The Problem We're Solving */}
      <ProblemStatement />
      <div className="relative py-10">
        <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center gap-2">
          <div
            className="w-px h-10 opacity-20"
            style={{ backgroundColor: "#71EEB8" }}
          />
          <div
            className="w-2 h-2 rounded-full"
            style={{ backgroundColor: "#71EEB8" }}
          />
        </div>
      </div>

      {/* 3. How: Our Core Principles */}
      <CorePrinciples />
      <div className="relative py-10">
        <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center gap-2">
          <div
            className="w-px h-10 opacity-20"
            style={{ backgroundColor: "#71EEB8" }}
          />
          <div
            className="w-2 h-2 rounded-full"
            style={{ backgroundColor: "#71EEB8" }}
          />
        </div>
      </div>

      <EurostatSection></EurostatSection>
      <div className="relative py-10">
        <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center gap-2">
          <div
            className="w-px h-10 opacity-20"
            style={{ backgroundColor: "#71EEB8" }}
          />
          <div
            className="w-2 h-2 rounded-full"
            style={{ backgroundColor: "#71EEB8" }}
          />
        </div>
      </div>

      {/* 5. Platform: CityOS [FUTURE SECTION]
          - Introduce the platform that manages the pipeline
          - Show how it connects telcos with statistical offices
          - Demonstrate data flow and management
          - Highlight security and compliance features
      */}
      <div className="relative py-10">
        <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center gap-2">
          <div
            className="w-px h-10 opacity-20"
            style={{ backgroundColor: "#71EEB8" }}
          />
          <div
            className="w-2 h-2 rounded-full"
            style={{ backgroundColor: "#71EEB8" }}
          />
        </div>
      </div>

      {/* 6. Applications: Real World Impact */}
      <ImpactSection />
      <div className="relative py-10">
        <div className="absolute left-1/2 transform -translate-x-1/2 flex flex-col items-center gap-2">
          <div
            className="w-px h-10 opacity-20"
            style={{ backgroundColor: "#71EEB8" }}
          />
          <div
            className="w-2 h-2 rounded-full"
            style={{ backgroundColor: "#71EEB8" }}
          />
        </div>
      </div>

      {/* 7. Products & Services [FUTURE SECTION]
          - Show the actual deliverables
          - Different tiers of service
          - Integration options
          - Support and consulting services
      */}

      {/* Visual footer connector */}
      <div className="max-w-7xl mx-auto px-6 py-10">
        <div
          className="h-px w-full opacity-20 rounded-full"
          style={{ backgroundColor: "#71EEB8" }}
        />
      </div>
    </div>
  );
};

export default LandingPage;